.color-transparent {
  color: "transparent";
}

.bg-transparent {
  background-color: "transparent";
}

.color-black {
  color: var(--black);
}

.bg-black {
  background-color: var(--black);
}

.color-primary {
  color: var(--primary);
}

.bg-primary {
  background-color: var(--primary);
}

.color-primary-10 {
  color: var(--primary-10);
}

.bg-primary-10 {
  background-color: var(--primary-10);
}

.color-primary-5 {
  color: var(--primary-5);
}

.bg-primary-5 {
  background-color: var(--primary-5);
}

.color-primary-7 {
  color: var(--primary-7);
}

.bg-primary-7 {
  background-color: var(--primary-7);
}

.color-primary-dark {
  color: var(--primary-dark);
}

.bg-primary-dark {
  background-color: var(--primary-dark);
}

.color-white {
  color: var(--white);
}

.bg-white {
  background-color: var(--white);
}

.color-accent-1 {
  color: var(--accent-1);
}

.bg-accent-1 {
  background-color: var(--accent-1);
}

.color-accent-2 {
  color: var(--accent-2);
}

.bg-accent-2 {
  background-color: var(--accent-2);
}

.color-accent-3 {
  color: var(--accent-3);
}

.bg-accent-3 {
  background-color: var(--accent-3);
}

.color-accent-4 {
  color: var(--accent-4);
}

.bg-accent-4 {
  background-color: var(--accent-4);
}

.color-accent-5 {
  color: var(--accent-5);
}

.bg-accent-5 {
  background-color: var(--accent-5);
}

.color-accent-6 {
  color: var(--accent-6);
}

.bg-accent-6 {
  background-color: var(--accent-6);
}

.color-accent-7 {
  color: var(--accent-7);
}

.bg-accent-7 {
  background-color: var(--accent-7);
}

.color-accent-8 {
  color: var(--accent-8);
}

.bg-accent-8 {
  background-color: var(--accent-8);
}

.color-accent-9 {
  color: var(--accent-9);
}

.bg-accent-9 {
  background-color: var(--accent-9);
}

.color-accent-10 {
  color: var(--accent-10);
}

.bg-accent-10 {
  background-color: var(--accent-10);
}

.color-neutral-1 {
  color: var(--neutral-1);
}

.bg-neutral-1 {
  background-color: var(--neutral-1);
}

.color-neutral-2 {
  color: var(--neutral-2);
}

.bg-neutral-2 {
  background-color: var(--neutral-2);
}

.color-neutral-3 {
  color: var(--neutral-3);
}

.bg-neutral-3 {
  background-color: var(--neutral-3);
}

.color-neutral-4 {
  color: var(--neutral-4);
}

.bg-neutral-4 {
  background-color: var(--neutral-4);
}

.color-neutral-5 {
  color: var(--neutral-5);
}

.bg-neutral-5 {
  background-color: var(--neutral-5);
}

.color-neutral-6 {
  color: var(--neutral-6);
}

.bg-neutral-6 {
  background-color: var(--neutral-6);
}

.color-neutral-7 {
  color: var(--neutral-7);
}

.bg-neutral-7 {
  background-color: var(--neutral-7);
}

.color-neutral-8 {
  color: var(--neutral-8);
}

.bg-neutral-8 {
  background-color: var(--neutral-8);
}

.color-neutral-9 {
  color: var(--neutral-9);
}

.bg-neutral-9 {
  background-color: var(--neutral-9);
}

.color-action-default {
  color: var(--action-default);
}

.bg-action-default {
  background-color: var(--action-default);
}

.color-action-default-hover {
  color: var(--action-default-hover);
}

.bg-action-default-hover {
  background-color: var(--action-default-hover);
}

.color-action-default-active {
  color: var(--action-default-active);
}

.bg-action-default-active {
  background-color: var(--action-default-active);
}

.color-action-secondary {
  color: var(--action-secondary);
}

.bg-action-secondary {
  background-color: var(--action-secondary);
}

.color-action-secondary-hover {
  color: var(--action-secondary-hover);
}

.bg-action-secondary-hover {
  background-color: var(--action-secondary-hover);
}

.color-action-secondary-active {
  color: var(--action-secondary-active);
}

.bg-action-secondary-active {
  background-color: var(--action-secondary-active);
}

.color-action-visited {
  color: var(--action-visited);
}

.bg-action-visited {
  background-color: var(--action-visited);
}

.color-function-error {
  color: #da1414;
}

.bg-function-error {
  background-color: #da1414;
}

.color-function-error-border {
  color: #f48989;
}

.bg-function-error-border {
  background-color: #f48989;
}

.color-function-error-background {
  color: #feefef;
}

.bg-function-error-background {
  background-color: #feefef;
}

.color-function-warning {
  color: #b95000;
}

.bg-function-warning {
  background-color: #b95000;
}

.color-function-warning-border {
  color: #ff8f3a;
}

.bg-function-warning-border {
  background-color: #ff8f3a;
}

.color-function-warning-background {
  color: #fff4ec;
}

.bg-function-warning-background {
  background-color: #fff4ec;
}

.color-function-success {
  color: #287d3c;
}

.bg-function-success {
  background-color: #287d3c;
}

.color-function-success-border {
  color: #5aca75;
}

.bg-function-success-border {
  background-color: #5aca75;
}

.color-function-success-background {
  color: #edf9f0;
}

.bg-function-success-background {
  background-color: #edf9f0;
}

.color-function-info {
  color: #89a7e0;
}

.bg-function-info {
  background-color: #89a7e0;
}

.color-function-info-border {
  color: #89a7e0;
}

.bg-function-info-border {
  background-color: #89a7e0;
}

.color-function-info-background {
  color: #eef2fa;
}

.bg-function-info-background {
  background-color: #eef2fa;
}

.elevation-1 {
  box-shadow: 0px 0.5px 3.35px 0px rgba(0, 0, 0, 0.14), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.032), 0px 2.3px 2px -0.6px rgba(0, 0, 0, 0.06);
}

.elevation-2 {
  box-shadow: 0px 0.8px 3.7px -0.33px rgba(0, 0, 0, 0.14), 0px 2px 3px 0.15px rgba(0, 0, 0, 0.034), 0px 2.6px 4px -0.2px rgba(0, 0, 0, 0.06);
}

.elevation-3 {
  box-shadow: 0px 1.1px 4.05px -0.66px rgba(0, 0, 0, 0.14), 0px 3px 4.5px 0.3px rgba(0, 0, 0, 0.036), 0px 2.9px 6px 0.2px rgba(0, 0, 0, 0.06);
}

.elevation-4 {
  box-shadow: 0px 1.4px 4.4px -0.99px rgba(0, 0, 0, 0.14), 0px 4px 6px 0.45px rgba(0, 0, 0, 0.038), 0px 3.2px 8px 0.6px rgba(0, 0, 0, 0.06);
}

.elevation-5 {
  box-shadow: 0px 2px 5.1px -1.65px rgba(0, 0, 0, 0.14), 0px 6px 9px 0.75px rgba(0, 0, 0, 0.042), 0px 3.8px 12px 1.4px rgba(0, 0, 0, 0.06);
}

.elevation-6 {
  box-shadow: 0px 2.6px 5.8px -2.31px rgba(0, 0, 0, 0.14), 0px 8px 12px 1.05px rgba(0, 0, 0, 0.046), 0px 4.4px 16px 2.2px rgba(0, 0, 0, 0.06);
}

.elevation-7 {
  box-shadow: 0px 3.5px 6.85px -3.3px rgba(0, 0, 0, 0.14), 0px 11px 16.5px 1.5px rgba(0, 0, 0, 0.052), 0px 5.3px 22px 3.4px rgba(0, 0, 0, 0.06);
}

.elevation-8 {
  box-shadow: 0px 4.7px 8.25px -4.62px rgba(0, 0, 0, 0.14), 0px 15px 22.5px 2.1px rgba(0, 0, 0, 0.06), 0px 6.5px 30px 5px rgba(0, 0, 0, 0.06);
}

.elevation-9 {
  box-shadow: 0px 7.4px 11.4px -7.59px rgba(0, 0, 0, 0.14), 0px 24px 36px 3.45px rgba(0, 0, 0, 0.078), 0px 9.2px 48px 8.6px rgba(0, 0, 0, 0.06);
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-space-around {
  align-items: space-around;
}

.align-space-between {
  align-items: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-stretch {
  align-items: stretch;
}

.align-content-baseline {
  align-content: baseline;
}

.align-content-center {
  align-content: center;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-space-around {
  align-content: space-around;
}

.align-content-space-between {
  align-content: space-between;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-stretch {
  align-content: stretch;
}

.justify-baseline {
  justify-content: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.justify-stretch {
  justify-content: stretch;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex {
  display: flex;
}

html .content #main-content.layout-content {
  margin: 0;
  max-width: none;
}

.layout-content .container {
  max-width: 1366px;
}
.layout-content .container.sidebar-layouts-section__layout-preview {
  padding: 0;
}
.layout-content .container.sidebar-layouts-section__layout-preview .sidebar-layouts-section__layout-preview__row {
  margin: 0;
}
@media (max-width: 599px) {
  .layout-content .container {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .layout-content div[class*=col-md] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .layout-content .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}
@media (min-width: 600px) {
  .layout-content .container {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .layout-content div[class*=col-md] {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .layout-content .row {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
}
@media (min-width: 900px) {
  .layout-content .container {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .layout-content div[class*=col-md] {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .layout-content .row {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.max-width-none {
  max-width: "none";
}

.max-width-xsmall {
  max-width: 320px;
}

.max-width-form-small {
  max-width: 400px;
}

.max-width-form {
  max-width: 500px;
}

.max-width-small {
  max-width: 520px;
}

.max-width-medium {
  max-width: 720px;
}

.max-width-large {
  max-width: 900px;
}

.max-width-xlarge {
  max-width: 1200px;
}

.max-width-full {
  max-width: 1366px;
}

.border-radius-none {
  border-radius: 0;
}

.border-radius-small {
  border-radius: 2px;
}

.border-radius-medium {
  border-radius: 4px;
}

.border-radius-large {
  border-radius: 6px;
}

.border-radius-xlarge {
  border-radius: 8px;
}

.font-family-mono {
  font-family: "Source Code Pro", Menlo, monospace;
}
.font-family-sans {
  font-family: "Source Sans Pro", Tahoma, "Trebuchet MS", sans-serif;
}
.font-family-serif {
  font-family: "Source Serif Pro", Georgia, Cambria, "Times New Roman", Times, serif;
}

.font-weight-bold {
  font-weight: 700;
}
.font-weight-extra-light {
  font-weight: 200;
}
.font-weight-light {
  font-weight: 300;
}
.font-weight-normal {
  font-weight: 400;
}
.font-weight-semi-bold {
  font-weight: 600;
}

.content .layout-content h1, .font-size-heading-f1 {
  font-weight: 600;
}
@media (max-width: 599px) {
  .content .layout-content h1, .font-size-heading-f1 {
    font-size: 2rem;
    line-height: 1.125;
  }
}
@media (min-width: 600px) {
  .content .layout-content h1, .font-size-heading-f1 {
    font-size: 2.625rem;
    line-height: 1.143;
  }
}
@media (min-width: 900px) {
  .content .layout-content h1, .font-size-heading-f1 {
    font-size: 3rem;
    line-height: 1.167;
  }
}
@media (min-width: 1200px) {
  .content .layout-content h1, .font-size-heading-f1 {
    font-size: 3.438rem;
    line-height: 1.018;
  }
}

.content .layout-content h2, .font-size-heading-f2 {
  font-weight: 600;
}
@media (max-width: 599px) {
  .content .layout-content h2, .font-size-heading-f2 {
    font-size: 1.813rem;
    line-height: 1.103;
  }
}
@media (min-width: 600px) {
  .content .layout-content h2, .font-size-heading-f2 {
    font-size: 2.188rem;
    line-height: 1.029;
  }
}
@media (min-width: 900px) {
  .content .layout-content h2, .font-size-heading-f2 {
    font-size: 2.438rem;
    line-height: 1.026;
  }
}
@media (min-width: 1200px) {
  .content .layout-content h2, .font-size-heading-f2 {
    font-size: 2.75rem;
    line-height: 1.091;
  }
}

.content .layout-content h3, .font-size-heading-f3 {
  font-weight: 600;
}
@media (max-width: 599px) {
  .content .layout-content h3, .font-size-heading-f3 {
    font-size: 1.625rem;
    line-height: 1.23;
  }
}
@media (min-width: 600px) {
  .content .layout-content h3, .font-size-heading-f3 {
    font-size: 1.875rem;
    line-height: 1.2;
  }
}
@media (min-width: 900px) {
  .content .layout-content h3, .font-size-heading-f3 {
    font-size: 2rem;
    line-height: 1.125;
  }
}
@media (min-width: 1200px) {
  .content .layout-content h3, .font-size-heading-f3 {
    font-size: 2.188rem;
    line-height: 1.143;
  }
}

.content .layout-content h4, .font-size-heading-f4 {
  font-weight: 600;
}
@media (max-width: 599px) {
  .content .layout-content h4, .font-size-heading-f4 {
    font-size: 1.375rem;
    line-height: 1.244;
  }
}
@media (min-width: 600px) {
  .content .layout-content h4, .font-size-heading-f4 {
    font-size: 1.563rem;
    line-height: 1.28;
  }
}
@media (min-width: 900px) {
  .content .layout-content h4, .font-size-heading-f4 {
    font-size: 1.625rem;
    line-height: 1.230769231;
  }
}
@media (min-width: 1200px) {
  .content .layout-content h4, .font-size-heading-f4 {
    font-size: 1.75rem;
    line-height: 1.143;
  }
}

.content .layout-content h5, .font-size-heading-f5 {
  font-weight: 600;
}
@media (max-width: 599px) {
  .content .layout-content h5, .font-size-heading-f5 {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
@media (min-width: 600px) {
  .content .layout-content h5, .font-size-heading-f5 {
    font-size: 1.313rem;
    line-height: 1.143;
  }
}
@media (min-width: 900px) {
  .content .layout-content h5, .font-size-heading-f5 {
    font-size: 1.375rem;
    line-height: 1.273;
  }
}
@media (min-width: 1200px) {
  .content .layout-content h5, .font-size-heading-f5 {
    font-size: 1.375rem;
    line-height: 1.244;
  }
}

.content .layout-content .font-size-display-large, .font-size-display-large {
  font-weight: 700;
}
@media (max-width: 599px) {
  .content .layout-content .font-size-display-large, .font-size-display-large {
    font-size: 2.875rem;
    line-height: 0.957;
  }
}
@media (min-width: 600px) {
  .content .layout-content .font-size-display-large, .font-size-display-large {
    font-size: 4.438rem;
    line-height: 1.01;
  }
}
@media (min-width: 900px) {
  .content .layout-content .font-size-display-large, .font-size-display-large {
    font-size: 5.563rem;
    line-height: 0.989;
  }
}
@media (min-width: 1200px) {
  .content .layout-content .font-size-display-large, .font-size-display-large {
    font-size: 6.688rem;
    line-height: 0.972;
  }
}

.content .layout-content .font-size-display-small, .font-size-display-small {
  font-weight: 700;
}
@media (max-width: 599px) {
  .content .layout-content .font-size-display-small, .font-size-display-small {
    font-size: 2.563rem;
    line-height: 0.976;
  }
}
@media (min-width: 600px) {
  .content .layout-content .font-size-display-small, .font-size-display-small {
    font-size: 2.938rem;
    line-height: 1.021;
  }
}
@media (min-width: 900px) {
  .content .layout-content .font-size-display-small, .font-size-display-small {
    font-size: 4.5rem;
    line-height: 1;
  }
}
@media (min-width: 1200px) {
  .content .layout-content .font-size-display-small, .font-size-display-small {
    font-size: 5.375rem;
    line-height: 1.023;
  }
}

.font-size-long-form-sans-serif {
  font-size: 1.063rem;
  line-height: 1.647;
}
@media (min-width: 900px) {
  .font-size-long-form-sans-serif {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.font-size-long-form-serif {
  font-size: 1.063rem;
  line-height: 1.647;
  font-family: "Source Serif Pro", Georgia, Cambria, "Times New Roman", Times, serif;
}
@media (min-width: 900px) {
  .font-size-long-form-serif {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

@media (max-width: 599px) {
  .font-size-paragraph-large {
    font-size: 1.25rem;
    line-height: 1.379;
  }
}
@media (min-width: 600px) {
  .font-size-paragraph-large {
    font-size: 1.438rem;
    line-height: 1.362;
  }
}
@media (min-width: 900px) {
  .font-size-paragraph-large {
    font-size: 1.563rem;
    line-height: 1.412;
  }
}
@media (min-width: 1200px) {
  .font-size-paragraph-large {
    font-size: 1.75rem;
    line-height: 1.286;
  }
}

.content .layout-content p, .font-size-paragraph-base {
  font-size: 1.125rem;
  line-height: 1.556;
}

.f-navigation-primary.nav-wrapper .adt-nav-text, .font-size-paragraph-base-semi-bold {
  font-size: 1.125rem;
  line-height: 1.556;
  font-weight: 600;
}

.font-size-paragraph-small {
  font-size: 1rem;
  line-height: 1.5;
}

.font-size-paragraph-xsmall {
  font-size: 0.9em;
  line-height: 1.389;
}

.font-size-paragraph-tiny {
  font-size: 0.688rem;
  line-height: 1.391;
}

.font-size-small-caps {
  font-size: 0.9rem;
  line-height: 1.389;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

body .content h5,
body .content .font-spec-5, body .content h4,
body .content .font-spec-4, body .content h3,
body .content .font-spec-3, body .content h2,
body .content .font-spec-2, body .content h1,
body .content .font-spec-1 {
  font-weight: 600;
  line-height: 1.25;
}

body .content .small-caps, body .content p.superfine-print, body .content p.large, body .content h5,
body .content .font-spec-5, body .content h4,
body .content .font-spec-4, body .content h3,
body .content .font-spec-3, body .content h2,
body .content .font-spec-2, body .content h1,
body .content .font-spec-1, body .content p.fine-print {
  margin: 0 0 16px;
}

body .content p.fine-print {
  font-size: 1em;
}

body .content h1,
body .content .font-spec-1 {
  font-size: 3em;
  line-height: 1.05;
}
@media (min-width: 768px) and (max-width: 979px) {
  body .content h1,
  body .content .font-spec-1 {
    font-size: 2.5em;
  }
}
@media (max-width: 767px) {
  body .content h1,
  body .content .font-spec-1 {
    font-size: 2em;
  }
}

body .content h2,
body .content .font-spec-2 {
  font-size: 2.25em;
}
@media (min-width: 768px) and (max-width: 979px) {
  body .content h2,
  body .content .font-spec-2 {
    font-size: 2em;
  }
}
@media (max-width: 767px) {
  body .content h2,
  body .content .font-spec-2 {
    font-size: 1.625em;
  }
}

body .content h3,
body .content .font-spec-3 {
  font-size: 1.75em;
}
@media (min-width: 768px) and (max-width: 979px) {
  body .content h3,
  body .content .font-spec-3 {
    font-size: 1.5em;
  }
}
@media (max-width: 767px) {
  body .content h3,
  body .content .font-spec-3 {
    font-size: 1.375em;
  }
}

body .content h4,
body .content .font-spec-4 {
  font-size: 1.125em;
}

body .content h5,
body .content .font-spec-5 {
  font-size: 1em;
}

body .content p.large {
  font-size: 1.75em;
  line-height: 1.25em;
}
@media (min-width: 768px) and (max-width: 979px) {
  body .content p.large {
    font-size: 1.5em;
  }
}
@media (max-width: 767px) {
  body .content p.large {
    font-size: 1.375em;
  }
}

body .content p.superfine-print {
  font-size: 0.85714em;
}

body .content .small-caps {
  font-size: 0.833125em;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 200;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-200.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-200.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-200.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-200.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-200.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-200.svg#Source_Code_Pro") format("svg");
}
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 300;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-300.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-300.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-300.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-300.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-300.svg#Source_Code_Pro") format("svg");
}
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 400;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-400.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-400.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-400.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-400.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-400.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-400.svg#Source_Code_Pro") format("svg");
}
@font-face {
  font-family: "Source Code Pro";
  font-style: italic;
  font-weight: 400;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-italic.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-italic.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-italic.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-italic.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-italic.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-italic.svg#Source_Code_Pro") format("svg");
}
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 600;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-600.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-600.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-600.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-600.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-600.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-600.svg#Source_Code_Pro") format("svg");
}
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 700;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-700.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-700.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-700.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-700.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-code-pro-v21-vietnamese_latin_cyrillic-700.svg#Source_Code_Pro") format("svg");
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-200.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-200.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-200.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-200.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-200.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-200.svg#Source_Sans_Pro") format("svg");
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-300.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-300.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-300.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-300.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-300.svg#Source_Sans_Pro") format("svg");
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-400.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-400.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-400.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-400.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-400.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-400.svg#Source_Sans_Pro") format("svg");
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-italic.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-italic.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-italic.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-italic.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-italic.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-italic.svg#Source_Sans_Pro") format("svg");
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-600.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-600.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-600.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-600.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-600.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-600.svg#Source_Sans_Pro") format("svg");
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-700.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-700.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-700.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-700.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/source-sans-pro-v21-vietnamese_latin_cyrillic-700.svg#Source_Sans_Pro") format("svg");
}
@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 200;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-200.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-200.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-200.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-200.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-200.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-200.svg#Noto_Sans_SC") format("svg");
}
@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 300;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-300.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-300.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-300.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-300.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-300.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-300.svg#Noto_Sans_SC") format("svg");
}
@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 400;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-400.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-400.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-400.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-400.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-400.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-400.svg#Noto_Sans_SC") format("svg");
}
@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 500;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-500.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-500.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-500.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-500.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-500.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-500.svg#Noto_Sans_SC") format("svg");
}
@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 700;
  src: url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-700.eot");
  src: local(""), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-700.eot?#iefix") format("embedded-opentype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-700.woff2") format("woff2"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-700.woff") format("woff"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-700.ttf") format("truetype"), url("/o/osb-www-foundations-theme-contributor/fonts/noto-sans-sc-v26-latin_chinese-simplified-700.svg#Noto_Sans_SC") format("svg");
}
body .content {
  font: 400 16px/1.5 "Source Sans Pro", Tahoma, "Trebuchet MS", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
  right: 0;
}
body .content b,
body .content strong {
  font-weight: 700;
}
body .content blockquote {
  font-size: 1.25em;
  line-height: 1.25;
}
@media (max-width: 979px) {
  body .content blockquote {
    font-size: 1.5em;
    line-height: 1.5;
  }
}
body .content .long-form {
  font-size: 1.25em;
  line-height: 1.5;
}
body .content .long-form a:not(.btn) {
  text-decoration: underline;
}
body .content .long-form h2 {
  font-size: 1.5em;
  font-weight: 400;
  margin-top: 1em;
}
body .content .long-form h2:first-child {
  margin-top: 0.25em;
}
body .content .long-form h3 {
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 1em;
}
body .content .long-form h4 {
  font-size: 1.2em;
  margin-top: 1em;
}
body .content .long-form li {
  line-height: inherit;
  margin-bottom: 1em;
}
body .content .long-form ol,
body .content .long-form p:not(.small-caps),
body .content .long-form ul {
  font-family: "Source Serif Pro", Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 400;
  margin-bottom: 1em;
}
body .content .long-form p.introduction {
  font-size: 1.2em;
}
body .content .long-form ul:not(.unstyled) {
  list-style: none;
}
body .content .long-form ul:not(.unstyled) li:before {
  background-color: var(--neutral-5);
  border-radius: 0.25em;
  content: "";
  display: inline-block;
  height: 0.25em;
  margin: 0 0.5em 0.25em -1em;
  width: 0.25em;
}
body .content .long-form .long-form-title {
  margin-bottom: calc(2em + 4px);
  position: relative;
}
body .content .long-form .long-form-title:after {
  background: #134194;
  bottom: -1em;
  content: "";
  display: block;
  height: 4px;
  position: absolute;
  width: 56px;
}
@media (min-width: 768px) and (max-width: 979px) {
  body .content .long-form h2 {
    font-size: 1.4em;
  }
  body .content .long-form h3 {
    font-size: 1.2em;
  }
}
@media (max-width: 767px) {
  body .content .long-form {
    font-size: 1.1em;
    line-height: 1.6em;
  }
  body .content .long-form h2 {
    font-size: 1.2em;
    font-weight: 600;
  }
  body .content .long-form h3 {
    font-size: 1.125em;
  }
}
body .content .page-heading .preheading,
body .content .preheading {
  font-size: 1em;
}
body .content .subheading {
  color: var(--neutral-5);
  font-weight: 400;
}
body .content .source-text {
  color: var(--neutral-5);
  font-size: 0.75em;
  margin: 0.5em 0;
}

.aspect-ratio-item-flush-110 {
  max-width: none;
  position: absolute;
  width: 110%;
}

.aspect-ratio-item-flush-120 {
  max-width: none;
  position: absolute;
  width: 120%;
}

.aspect-ratio-item-flush-130 {
  max-width: none;
  position: absolute;
  width: 130%;
}

.aspect-ratio-item-vertical-flush-110 {
  height: 110%;
  max-height: none;
  position: absolute;
}

.aspect-ratio-item-vertical-flush-120 {
  height: 120%;
  max-height: none;
  position: absolute;
}

.aspect-ratio-item-vertical-flush-130 {
  height: 130%;
  max-height: none;
  position: absolute;
}

.aspect-ratio-object-fit-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.aspect-ratio-object-fit-cover .fragments-image-div,
.aspect-ratio-object-fit-cover .fragments-div,
.aspect-ratio-object-fit-cover .fragments-editor__editable[type=image] {
  height: 100%;
  position: absolute;
  width: 100%;
}
.aspect-ratio-object-fit-cover .fragments-image-div img,
.aspect-ratio-object-fit-cover .fragments-div img,
.aspect-ratio-object-fit-cover .fragments-editor__editable[type=image] img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  width: 100%;
}

.osb-svg--monospaced {
  height: 100%;
  width: 100%;
}
.osb-svg--monospaced .fragments-div,
.osb-svg--monospaced .fragments-editor__editable[type=html] {
  align-items: center;
  display: flex;
  height: 100%;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
}
.osb-svg--monospaced .fragments-div svg,
.osb-svg--monospaced .fragments-editor__editable[type=html] svg {
  height: auto;
  width: 100%;
}
.osb-svg--monospaced .html-placeholder {
  background-color: #a7a9bc;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}
.osb-svg--monospaced .html-placeholder-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 599px) {
  .hide-phone {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .hide-tablet-portrait {
    display: none !important;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .hide-tablet-landscape {
    display: none !important;
  }
}

@media (min-width: 1200px) and (max-width: 1799px) {
  .hide-desktop {
    display: none !important;
  }
}

@media (min-width: 1800px) {
  .hide-large-desktop-up {
    display: none !important;
  }
}

.hover-text-decoration-none:hover {
  text-decoration: none;
}

.content .align-center {
  align-items: center;
}
.content .align-start {
  align-items: flex-start;
}
.content .align-content-center {
  align-content: center;
}
.content .block-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
}
.content .block-container .block {
  box-sizing: border-box;
}
.content .block-container .block.preview-block {
  padding: 0.5em;
  width: 25%;
}
.content .block-container .block.preview-block a {
  border: 1px solid;
  border-radius: 4px;
  display: block;
  text-decoration: none;
}
@media (min-width: 768px) and (max-width: 979px) {
  .content .block-container .block.preview-block {
    width: 33.333%;
  }
}
.content .block-container .block.right-block {
  padding-left: 1em;
}
@media (max-width: 767px) {
  .content .block-container .block {
    width: 100% !important;
  }
}
.content .border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.content .border-left {
  border-left-style: solid;
  border-left-width: 1px;
}
.content .border-right {
  border-right-style: solid;
  border-right-width: 1px;
}
.content .border-top {
  border-top-style: solid;
  border-top-width: 1px;
}
.content .flex {
  display: flex;
}
.content .flex-column {
  flex-direction: column;
}
.content .flex-column-reverse {
  flex-direction: column-reverse;
}
.content .flex-row {
  flex-direction: row;
}
.content .flex-row-reverse {
  flex-direction: row-reverse;
}
.content .font-weight-lighter {
  font-weight: 200;
}
.content .font-weight-bold {
  font-weight: 600;
}
.content .full-screen {
  max-width: none !important;
}
.content .full-screen .portlet-column {
  max-width: none;
}
.content .justify-center {
  justify-content: center;
}
.content .justify-end {
  justify-content: flex-end;
}
.content .justify-space-around {
  justify-content: space-around;
}
.content .justify-space-between {
  justify-content: space-between;
}
.content .justify-start {
  justify-content: flex-start;
}
.content .max-full,
.content .max-lg,
.content .max-med,
.content .max-sm {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 1em;
}
.content .max-full {
  max-width: 1200px;
}
.content .max-lg {
  max-width: 960px;
}
.content .max-med {
  max-width: 720px;
}
.content .max-sm {
  max-width: 480px;
}
.content .no-padding {
  padding: 0;
}
.content .no-padding-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.content .no-padding-vertical {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.content .small-padding {
  padding: 0.5em;
}
.content .small-padding-horizontal {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.content .small-padding-vertical {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}
.content .standard-padding {
  padding: 1em;
}
.content .standard-padding-horizontal {
  padding-left: 1em;
  padding-right: 1em;
}
.content .standard-padding-vertical {
  padding-bottom: 1em;
  padding-top: 1em;
}

body.dark,
.dark body {
  background-image: linear-gradient(95.92deg, var(--primary-dark) 20.61%, var(--primary) 100%);
}
body.dark .page-editor__layout-viewport,
body.dark .page-editor__layout-viewport__resizer,
.dark body .page-editor__layout-viewport,
.dark body .page-editor__layout-viewport__resizer {
  background-color: transparent;
}

.dark {
  color: var(--white);
}

.light {
  color: var(--black);
}

.content .layout-content {
  font: 400 16px/1.5 "Source Sans Pro", Tahoma, "Trebuchet MS", sans-serif;
}
.content .layout-content *,
.content .layout-content *::before,
.content .layout-content *::after {
  box-sizing: border-box !important;
}
@media (min-width: 600px) {
  .content .layout-content .text-center-tablet-portrait-up {
    text-align: center;
  }
}

.content .layout-content .fragments-editor-sidebar-section__title {
  font-size: 18px;
  font-weight: 600;
  margin: 32px 0 24px 0;
}

.fragments-editor__editable[type=text],
.fragments-editor__editable[type=rich-text] {
  max-width: 100%;
  word-wrap: break-word;
}

.zh_CN .content .layout-content {
  font-family: "Source Sans Pro", "Noto Sans SC", Tahoma, "Trebuchet MS", sans-serif;
}

.bg-gradient-blue-purple {
  background: linear-gradient(90deg, #0160f6 0.46%, #720bde 100%);
}

.bg-gradient-blue-red {
  background: linear-gradient(45deg, #0160f6 0%, #f60c56 100%);
}

.bg-gradient-purple-blue {
  background: linear-gradient(180deg, #710cdf 0%, #2446ef 1102.08%);
}

.portlet {
  margin-bottom: 0;
}

.osb-icon {
  fill: currentColor;
  height: 1.5rem;
  width: 1.5rem;
}

.osb-icon--scale {
  height: 1.5em;
  width: 1.5em;
}

.osb-icon--content-edge {
  padding: 6px;
}

.osb-inline-link {
  color: var(--action-default);
  font-size: 18px;
}
.osb-inline-link:visited {
  color: var(--action-visited);
}
.osb-inline-link:hover {
  color: var(--action-default-hover);
}
.osb-inline-link:focus, .osb-inline-link.osb-btn--focus {
  color: var(--action-default-hover);
}
.osb-inline-link:active, .osb-inline-link.osb-btn--active {
  color: var(--action-default-active);
}
.osb-inline-link:disabled, .osb-inline-link.osb-btn--disabled {
  color: var(--action-default);
}
.osb-inline-link--secondary {
  color: var(--neutral-5);
  font-size: 18px;
  text-decoration: underline;
}
.osb-inline-link--secondary:visited {
  color: var(--neutral-5);
}
.osb-inline-link--secondary:hover {
  color: var(--neutral-5);
}
.osb-inline-link--secondary:focus, .osb-inline-link--secondary.osb-btn--focus {
  color: var(--neutral-5);
}
.osb-inline-link--secondary:active, .osb-inline-link--secondary.osb-btn--active {
  color: var(--neutral-5);
}
.osb-inline-link--secondary:disabled, .osb-inline-link--secondary.osb-btn--disabled {
  color: var(--neutral-5);
}
.osb-inline-link--light {
  color: rgba(var(--white), 0.7);
}
.osb-inline-link--light:visited {
  color: rgba(var(--white), 0.7);
}
.osb-inline-link--light:hover {
  color: rgba(var(--white), 0.7);
}
.osb-inline-link--light:focus, .osb-inline-link--light.osb-btn--focus {
  color: rgba(var(--white), 0.7);
}
.osb-inline-link--light:active, .osb-inline-link--light.osb-btn--active {
  color: rgba(var(--white), 0.7);
}
.osb-inline-link--light:disabled, .osb-inline-link--light.osb-btn--disabled {
  color: rgba(var(--white), 0.7);
}
.osb-inline-link--underline {
  text-decoration: underline;
}
.osb-inline-link--underline:hover {
  text-decoration: none;
}
.osb-return-link {
  align-items: center;
  background-color: transparent;
  border-width: 0;
  color: var(--neutral-5);
  display: inline-flex;
  font-size: 0.9rem;
  line-height: 27px;
  max-width: 100%;
  padding: 0;
  text-decoration: none;
  word-wrap: break-word;
}
.osb-return-link:visited {
  color: var(--neutral-5);
}
.osb-return-link:hover {
  color: var(--neutral-3);
  text-decoration: none;
}
.osb-return-link:focus, .osb-return-link.osb-btn--focus {
  color: var(--neutral-3);
}
.osb-return-link:active, .osb-return-link.osb-btn--active {
  color: var(--black);
}
.osb-return-link:disabled, .osb-return-link.osb-btn--disabled {
  background-color: transparent;
  color: var(--neutral-5);
}
.osb-return-link > div {
  display: inline;
}
.osb-return-link > svg {
  flex-shrink: 0;
  width: 1.5em;
}

.osb-cta {
  align-items: center;
  background-color: transparent;
  border-width: 0;
  color: var(--action-default);
  display: inline-flex;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 27px;
  max-width: 100%;
  text-decoration: none;
  word-wrap: break-word;
}
.osb-cta:visited {
  color: var(--action-visited);
}
.osb-cta:hover {
  color: var(--action-default-hover);
  text-decoration: none;
}
.osb-cta:focus, .osb-cta.osb-btn--focus {
  color: var(--action-default-hover);
}
.osb-cta:active, .osb-cta.osb-btn--active {
  color: var(--action-default-active);
}
.osb-cta:disabled, .osb-cta.osb-btn--disabled {
  background-color: transparent;
  color: var(--action-default);
}
.osb-cta > div {
  display: inline;
}
.osb-cta > svg {
  flex-shrink: 0;
  margin-top: 3px;
}
.osb-cta .osb-icon--content-edge.osb-icon--scale {
  margin-left: -4px;
  padding: 7px;
}

.osb-cta--animate:hover > svg:first-child {
  transform: translateX(-0.25rem);
}
.osb-cta--animate:hover > svg:last-child {
  transform: translateX(0.25rem);
}
.osb-cta--animate > svg {
  transition: transform 0.5s ease;
}

.osb-cta--large {
  font-size: 1.5rem;
  line-height: 36px;
}
.osb-cta--large .osb-icon--content-edge.osb-icon--scale {
  margin-left: -5px;
  padding: 11px;
}

.osb-cta--light,
.dark .osb-cta {
  color: var(--white);
}
.osb-cta--light:visited,
.dark .osb-cta:visited {
  color: var(--white);
}
.osb-cta--light:hover,
.dark .osb-cta:hover {
  color: var(--white);
}
.osb-cta--light:focus, .osb-cta--light.osb-btn--focus,
.dark .osb-cta:focus,
.dark .osb-cta.osb-btn--focus {
  color: var(--white);
}
.osb-cta--light:active, .osb-cta--light.osb-btn--active,
.dark .osb-cta:active,
.dark .osb-cta.osb-btn--active {
  color: var(--white);
}
.osb-cta--light:disabled, .osb-cta--light.osb-btn--disabled,
.dark .osb-cta:disabled,
.dark .osb-cta.osb-btn--disabled {
  color: var(--white);
}
.osb-btn {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  box-shadow: 0px 0.8px 3.7px -0.33px rgba(0, 0, 0, 0.14), 0px 2px 3px 0.15px rgba(0, 0, 0, 0.034), 0px 2.6px 4px -0.2px rgba(0, 0, 0, 0.06);
  color: var(--black);
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  margin: 0.25em 0 0.375em;
  max-width: 100%;
  padding-bottom: 0.6875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.6875rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  user-select: none;
  vertical-align: middle;
  word-wrap: break-word;
  z-index: 1;
}
.osb-btn:visited {
  color: var(--black);
}
.osb-btn:hover {
  box-shadow: 0px 1.1px 4.05px -0.66px rgba(0, 0, 0, 0.14), 0px 3px 4.5px 0.3px rgba(0, 0, 0, 0.036), 0px 2.9px 6px 0.2px rgba(0, 0, 0, 0.06);
  color: var(--black);
  text-decoration: none;
  transform: translateY(calc(-3px - 1%));
  transition: all 0.2s;
}
.osb-btn:focus, .osb-btn.osb-btn--focus {
  box-shadow: 0 5px 11px -5px #000;
  color: var(--black);
  outline: 0;
  transform: translateY(calc(-3px - 1%));
  transition: all 0.2s;
}
.osb-btn:active, .osb-btn.osb-btn--active {
  box-shadow: 0 5px 8px -5px rgba(0, 0, 0, 0.5);
  color: var(--black);
  transform: translateY(0);
  transition: all 0.1s;
}
.osb-btn:disabled, .osb-btn.osb-btn--disabled {
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0px 0.8px 3.7px -0.33px rgba(0, 0, 0, 0.14), 0px 2px 3px 0.15px rgba(0, 0, 0, 0.034), 0px 2.6px 4px -0.2px rgba(0, 0, 0, 0.06);
  color: var(--black);
  cursor: not-allowed;
  opacity: 0.5;
  transform: none;
}
.osb-btn:disabled:active, .osb-btn.osb-btn--disabled:active {
  pointer-events: none;
}

.osb-btn--icon-start {
  padding-left: 2.5rem;
  position: relative;
}
.osb-btn--icon-start svg {
  height: 1.5em;
  left: 0.6875rem;
  position: absolute;
  top: 50%;
  transform: translateY(-48%);
  width: 1.5em;
}

.osb-btn--icon-end {
  padding-right: 2.5rem;
  position: relative;
}
.osb-btn--icon-end svg {
  height: 1.5em;
  position: absolute;
  right: 0.6875rem;
  top: 50%;
  transform: translateY(-48%);
  width: 1.5em;
}

.osb-btn--large {
  font-size: 1.125em;
  padding-bottom: 0.875rem;
  padding-left: 1.1875rem;
  padding-right: 1.1875rem;
  padding-top: 0.875rem;
}
.osb-btn--large.osb-btn--icon-start {
  padding-left: 2.75rem;
}
.osb-btn--large.osb-btn--icon-start svg {
  left: 0.75rem;
}
.osb-btn--large.osb-btn--icon-end {
  padding-right: 2.75rem;
}
.osb-btn--large.osb-btn--icon-end svg {
  right: 0.75rem;
}

.osb-btn--small {
  font-size: 0.875em;
  margin: 0.5rem auto;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
}
@media (min-width: 900px) {
  .osb-btn--small {
    margin: 0;
  }
}
.osb-btn--small.osb-btn--icon-start {
  padding-left: 1.75rem;
}
.osb-btn--small.osb-btn--icon-start svg {
  left: 0.25rem;
}
.osb-btn--small.osb-btn--icon-end {
  padding-right: 1.75rem;
}
.osb-btn--small.osb-btn--icon-end svg {
  right: 0.25rem;
}

.osb-btn--block {
  display: block;
  width: 100%;
}

.osb-btn--monospaced {
  align-items: center;
  box-shadow: none;
  display: inline-flex;
  height: 2.5rem;
  justify-content: center;
  line-height: 1;
  padding: 0;
  transform: none;
  width: 2.5rem;
}
.osb-btn--monospaced:hover {
  box-shadow: none;
  transform: none;
}
.osb-btn--monospaced:focus, .osb-btn--monospaced.osb-btn--focus {
  box-shadow: none;
  transform: none;
}
.osb-btn--monospaced:disabled, .osb-btn--monospaced.osb-btn--disabled {
  box-shadow: none;
}
.osb-btn--monospaced.osb-btn--large {
  height: 3rem;
  width: 3rem;
}
.osb-btn--monospaced.osb-btn--small {
  height: 2rem;
  width: 2rem;
}
.osb-btn--flat {
  margin-bottom: 0;
  margin-top: 0;
  transition: none;
}
.osb-btn--flat:hover {
  transform: none;
}
.osb-btn--flat:focus, .osb-btn--flat.osb-btn--focus {
  transform: none;
}
.osb-btn--flat:active, .osb-btn--flat.osb-btn--active {
  transform: none;
  transition: none;
}
.osb-btn--primary {
  background-color: var(--action-default);
  border-color: var(--action-default);
  color: var(--white);
}
.osb-btn--primary:visited {
  color: var(--white);
}
.osb-btn--primary:hover {
  background-color: var(--action-default-hover);
  border-color: var(--action-default-hover);
  color: var(--white);
}
.osb-btn--primary:focus, .osb-btn--primary.osb-btn--focus {
  background-color: var(--action-default-hover);
  border-color: var(--action-default-hover);
  color: var(--white);
}
.osb-btn--primary:active, .osb-btn--primary.osb-btn--active {
  background-color: var(--action-default-active);
  border-color: var(--action-default-active);
  color: var(--white);
}
.osb-btn--primary:disabled, .osb-btn--primary.osb-btn--disabled {
  background-color: var(--action-default);
  border-color: var(--action-default);
  color: var(--white);
}
.osb-btn--secondary {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--action-default);
}
.osb-btn--secondary:visited {
  color: var(--action-default);
}
.osb-btn--secondary:hover {
  color: var(--action-default-hover);
}
.osb-btn--secondary:focus, .osb-btn--secondary.osb-btn--focus {
  color: var(--action-default-hover);
}
.osb-btn--secondary:active, .osb-btn--secondary.osb-btn--active {
  color: var(--action-default-active);
}
.osb-btn--secondary:disabled, .osb-btn--secondary.osb-btn--disabled {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--action-default);
}
.osb-btn--alternate {
  background-color: var(--accent-6);
  border-color: var(--accent-6);
  color: var(--white);
}
.osb-btn--alternate:visited {
  color: var(--white);
}
.osb-btn--alternate:hover {
  background-color: #169545;
  border-color: #169545;
  color: var(--white);
}
.osb-btn--alternate:focus, .osb-btn--alternate.osb-btn--focus {
  background-color: #169545;
  border-color: #169545;
  color: var(--white);
}
.osb-btn--alternate:active, .osb-btn--alternate.osb-btn--active {
  background-color: #127f3a;
  border-color: #127f3a;
  color: var(--white);
}
.osb-btn--alternate:disabled, .osb-btn--alternate.osb-btn--disabled {
  background-color: var(--accent-6);
  border-color: var(--accent-6);
  color: var(--white);
}
.osb-btn--plaintext {
  background-color: transparent;
  box-shadow: none;
  color: var(--action-default);
  cursor: default;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 600;
  max-width: 100%;
  padding: 0.625rem 0.5rem;
  word-wrap: break-word;
}
.osb-btn--plaintext:visited {
  color: var(--action-default);
}
.osb-btn--plaintext:hover {
  box-shadow: none;
  color: var(--action-default);
}
.osb-btn--plaintext:focus, .osb-btn--plaintext.osb-btn--focus {
  box-shadow: none;
  color: var(--action-default);
}
.osb-btn--plaintext:active, .osb-btn--plaintext.osb-btn--active {
  color: var(--action-default);
}
.osb-btn--plaintext:disabled, .osb-btn--plaintext.osb-btn--disabled {
  background-color: transparent;
  box-shadow: none;
  color: var(--action-default);
}
.osb-btn--plaintext.osb-btn--icon-start {
  padding-left: 2rem;
}
.osb-btn--plaintext.osb-btn--icon-start > svg {
  left: 3px;
  margin-top: 0;
}
.osb-btn--plaintext.osb-btn--icon-end {
  padding-right: 2rem;
}
.osb-btn--plaintext.osb-btn--icon-end > svg {
  margin-top: 0;
  right: 3px;
}
.osb-btn--plaintext.osb-btn--small {
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
}
.osb-btn--plaintext.osb-btn--large {
  font-size: 1.5rem;
  line-height: 36px;
  padding-bottom: 0.34375rem;
  padding-top: 0.34375rem;
}
.osb-btn--plaintext.osb-btn--large.osb-btn--icon-start {
  padding-left: 2.5rem;
}
.osb-btn--plaintext.osb-btn--large.osb-btn--icon-start > svg {
  left: 1px;
}
.osb-btn--plaintext.osb-btn--large.osb-btn--icon-end {
  padding-right: 2.5rem;
}
.osb-btn--plaintext.osb-btn--large.osb-btn--icon-end > svg {
  right: 1px;
}

.osb-card {
  box-shadow: 0px 0.8px 3.7px -0.33px rgba(0, 0, 0, 0.14), 0px 2px 3px 0.15px rgba(0, 0, 0, 0.034), 0px 2.6px 4px -0.2px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  color: var(--black);
  margin-bottom: 0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.osb-card:hover {
  background-color: var(--white);
  box-shadow: 0px 1.1px 4.05px -0.66px rgba(0, 0, 0, 0.14), 0px 3px 4.5px 0.3px rgba(0, 0, 0, 0.036), 0px 2.9px 6px 0.2px rgba(0, 0, 0, 0.06);
  color: var(--action-default-hover);
  text-decoration: none;
  transform: translateY(calc(-3px - 1%));
  transition: all 0.2s;
}

body.mobile-tablet-nav-visible {
  overflow: hidden;
}

@media (min-width: 1200px) {
  body.nav-dropdown-menu-overflow {
    overflow: hidden;
  }
  body.nav-dropdown-menu-overflow.has-alert-container .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu .adt-submenu-outer-wrapper .adt-submenu-inner-wrapper {
    margin-bottom: 4rem;
  }
  body.nav-dropdown-menu-overflow .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu .adt-submenu-outer-wrapper .adt-submenu-inner-wrapper {
    margin-bottom: 3rem;
  }
}
.has-alert-container.fill-banner .f-navigation-primary.nav-wrapper {
  position: fixed;
}
.has-alert-container .f-navigation-primary.nav-wrapper {
  position: absolute;
  top: 48px;
}

.fill-banner .f-navigation-primary.nav-wrapper {
  box-shadow: 0px 7.4px 11.4px -7.59px rgba(0, 0, 0, 0.14), 0px 24px 36px 3.45px rgba(0, 0, 0, 0.078), 0px 9.2px 48px 8.6px rgba(0, 0, 0, 0.06);
  top: 0;
}

.has-control-menu .f-navigation-primary.nav-wrapper {
  top: 56px;
}
@media (max-width: 576px) {
  .has-control-menu .f-navigation-primary.nav-wrapper {
    top: 48px;
  }
}
.has-control-menu.has-alert-container.fill-banner .f-navigation-primary.nav-wrapper {
  top: 56px;
}
.has-control-menu.has-alert-container .f-navigation-primary.nav-wrapper {
  top: 48px;
}
.has-control-menu.has-edit-mode-menu.has-alert-container.fill-banner .f-navigation-primary.nav-wrapper,
.has-control-menu.has-edit-mode-menu.has-alert-container .f-navigation-primary.nav-wrapper,
.has-control-menu.has-edit-mode-menu.has-alert-container .f-navigation-primary.nav-wrapper .search-wrapper.search-open {
  position: fixed;
  top: calc(56px + 65px);
}
.has-control-menu.has-edit-mode-menu .f-navigation-primary {
  top: calc(56px + 65px);
}

.product-menu-open .f-navigation-primary.nav-wrapper {
  left: 320px;
}

.f-navigation-primary-padding {
  padding-top: 64px;
}
.f-navigation-primary-padding.utility-navigation-padding {
  padding-top: 112px;
}
@media (max-width: 599px) {
  .f-navigation-primary-padding.utility-navigation-padding {
    padding-top: 64px;
  }
}

@media (min-width: 1200px) {
  .f-navigation-primary.dark-theme.nav-wrapper .nav {
    background-color: var(--primary-dark);
  }
  .f-navigation-primary.dark-theme.nav-wrapper .nav .primary-nav .content-wrapper .nav-items-wrapper,
  .f-navigation-primary.dark-theme.nav-wrapper .nav .primary-nav .content-wrapper .liferay-logo:hover {
    background-color: transparent;
  }
  .f-navigation-primary.dark-theme.nav-wrapper .nav .primary-nav .content-wrapper .nav-items-wrapper .adt-nav-item .adt-nav-text::after {
    background: var(--white);
  }
  .f-navigation-primary.dark-theme.nav-wrapper .adt-nav-text {
    background-color: transparent;
    color: white;
  }
  .f-navigation-primary.dark-theme.nav-wrapper .adt-nav-text:hover, .f-navigation-primary.dark-theme.nav-wrapper .adt-nav-text:focus {
    background-color: var(--white);
  }
}
@media (min-width: 600px) and (max-width: 1199px) {
  .f-navigation-primary.dark-theme.nav-wrapper .mobile-buttons .mobile-menu {
    border-color: var(--white);
    color: var(--white);
  }
  .f-navigation-primary.dark-theme.nav-wrapper .primary-nav {
    background-color: var(--primary-dark);
  }
  .f-navigation-primary.dark-theme.nav-wrapper .utility-nav {
    display: none;
  }
}
@media (max-width: 599px) {
  .f-navigation-primary.dark-theme.nav-wrapper .utility-nav {
    background-color: var(--primary-dark);
    border-bottom-color: transparent;
  }
  .f-navigation-primary.dark-theme.nav-wrapper .utility-nav .content-wrapper .utility-nav-right .search-button {
    display: none;
  }
  .f-navigation-primary.dark-theme.nav-wrapper .utility-nav .content-wrapper .dropdown .utility-nav-link.language-selector {
    color: var(--white);
    opacity: 1;
  }
  .f-navigation-primary.dark-theme.nav-wrapper .utility-nav .content-wrapper .dropdown .utility-nav-link.language-selector svg {
    fill: var(--white);
  }
}

.f-navigation-primary.nav-wrapper {
  bottom: auto;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: box-shadow 0.3s ease;
  will-change: box-shadow;
  z-index: 980;
}
.f-navigation-primary.nav-wrapper.search-open {
  z-index: 982;
}
.f-navigation-primary.nav-wrapper .nav {
  background-color: var(--white);
}
.f-navigation-primary.nav-wrapper * {
  box-sizing: border-box;
}
.f-navigation-primary.nav-wrapper .utility-nav .liferay-logo {
  display: none;
}
.f-navigation-primary.nav-wrapper .adt-navigation {
  display: flex;
  flex-wrap: inherit;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.f-navigation-primary.nav-wrapper .adt-nav-item {
  outline: 0px solid transparent;
}
.f-navigation-primary.nav-wrapper .adt-nav-item:focus .adt-nav-text {
  background-color: #ebf2ff;
  color: var(--action-default-hover);
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-nav-text:focus {
  outline: none;
}
.f-navigation-primary.nav-wrapper .adt-nav-item.dropdown-open .adt-angle-down-svg {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(180deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}
.f-navigation-primary.nav-wrapper .adt-nav-item.dropdown-open .adt-nav-text {
  background-color: #ebf2ff;
  border-radius: 4px;
}
.f-navigation-primary.nav-wrapper .adt-nav-item.dropdown-open .adt-nav-text .adt-nav-title {
  color: var(--action-default-active);
}
.f-navigation-primary.nav-wrapper .adt-nav-item.dropdown-open .adt-submenu {
  margin: 0;
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu {
  background-clip: padding-box;
  background-color: #fff;
  border-color: #e7e7ed;
  border-style: solid;
  border-width: 0;
  display: block;
  float: left;
  left: 0;
  list-style: none;
  margin: 0.3125rem 0 0;
  max-height: 80vh;
  max-width: none;
  min-height: auto;
  opacity: 0;
  overflow: auto;
  padding: 1rem 4rem 4.5rem 4rem;
  position: absolute;
  right: 0;
  transform: translateY(-100px);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.1s linear, visibility 0.3s linear;
  visibility: hidden;
  z-index: -1;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu .adt-submenu-outer-wrapper {
  width: 100%;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu .adt-submenu-outer-wrapper .adt-submenu-inner-wrapper {
  display: grid;
  grid-column-gap: 4rem;
  grid-row-gap: 2.5rem;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-header {
  border-bottom: 1px solid var(--neutral-7);
  -ms-grid-row: span 1;
  grid-row-end: span 1;
  -ms-grid-row-span: 1;
  grid-row-start: span 1;
  list-style: none;
  margin-bottom: -1rem;
  padding-bottom: 0.5rem;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content {
  display: flex;
  list-style: none;
  max-width: 274px;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content.image-type {
  max-width: none;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content .adt-submenu-item-link {
  display: flex;
  transition: box-shadow 0.1s linear, background-color 0.1s linear;
  user-select: none;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content .adt-submenu-item-link:hover, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content .adt-submenu-item-link:focus {
  background-color: #ebf2ff;
  border-radius: 0.5px;
  box-shadow: 0 0 0 8px #ebf2ff;
  outline: none;
  text-decoration: none;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content .adt-submenu-item-link:hover .adt-submenu-item-title, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content .adt-submenu-item-link:focus .adt-submenu-item-title {
  color: var(--action-default-hover);
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content .adt-submenu-item-link .adt-submenu-item-title {
  padding-bottom: 0.5rem;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-image {
  border-radius: 4px;
  height: 94px;
  margin-right: 1rem;
  object-fit: cover;
  width: 94px;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-preheader {
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  line-height: 1.25rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section {
  -webkit-align-items: start;
  align-items: start;
  -webkit-box-align: start;
  display: -ms-grid;
  display: grid;
  -ms-flex-align: start;
  grid-auto-columns: 1fr;
  grid-auto-rows: min-content;
  grid-column-gap: 4rem;
  grid-row-gap: 2.5rem;
  list-style: none;
  padding: 2rem 0;
  width: 100%;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section.light-blue {
  background-color: var(--primary-5);
  border-radius: 8px;
  padding: 2rem;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._3-section-span {
  -ms-grid-column-span: 3;
  grid-column-start: span 3;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._3-section-span .adt-submenu-header {
  -ms-grid-column-span: 3;
  grid-column-start: span 3;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._4-section-span {
  -ms-grid-column-span: 4;
  grid-column-start: span 4;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._4-section-span .adt-submenu-header {
  -ms-grid-column-span: 4;
  grid-column-start: span 4;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._5-section-span {
  -ms-grid-column-span: 5;
  grid-column-start: span 5;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._5-section-span .adt-submenu-header {
  -ms-grid-column: 2/span 5;
  -ms-grid-column-span: 5;
  grid-column-start: span 5;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._6-section-span {
  -ms-grid-column-span: 6;
  grid-column-start: span 6;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._6-section-span .adt-submenu-header {
  -ms-grid-column: 2/span 6;
  -ms-grid-column-span: 6;
  grid-column-start: span 6;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._7-section-span {
  -ms-grid-column-span: 7;
  grid-column-start: span 7;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._7-section-span .adt-submenu-header {
  -ms-grid-column: 2/span 7;
  -ms-grid-column-span: 7;
  grid-column-start: span 7;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._8-section-span {
  -ms-grid-column-span: 8;
  grid-column-start: span 8;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._8-section-span .adt-submenu-header {
  -ms-grid-column: 2/span 8;
  -ms-grid-column-span: 8;
  grid-column-start: span 8;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._9-section-span {
  -ms-grid-column-span: 9;
  grid-column-start: span 9;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._9-section-span .adt-submenu-header {
  -ms-grid-column: 2/span 9;
  -ms-grid-column-span: 9;
  grid-column-start: span 9;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._10-section-span {
  -ms-grid-column-span: 10;
  grid-column-start: span 10;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._10-section-span .adt-submenu-header {
  -ms-grid-column: 2/span 10;
  -ms-grid-column-span: 10;
  grid-column-start: span 10;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._11-section-span {
  -ms-grid-column-span: 11;
  grid-column-start: span 11;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._11-section-span .adt-submenu-header {
  -ms-grid-column: 2/span 11;
  -ms-grid-column-span: 11;
  grid-column-start: span 11;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._12-section-span {
  -ms-grid-column-span: 12;
  grid-column-start: span 12;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._12-section-span .adt-submenu-header {
  -ms-grid-column: 2/span 12;
  -ms-grid-column-span: 12;
  grid-column-start: span 12;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-1 {
  grid-column-start: span 1;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-2 {
  grid-column-start: span 2;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-3 {
  grid-column-start: span 3;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-4 {
  grid-column-start: span 4;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-5 {
  grid-column-start: span 5;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-6 {
  grid-column-start: span 6;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-7 {
  grid-column-start: span 7;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-8 {
  grid-column-start: span 8;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-9 {
  grid-column-start: span 9;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-10 {
  grid-column-start: span 10;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-11 {
  grid-column-start: span 11;
}
.f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-12 {
  grid-column-start: span 12;
}
.f-navigation-primary.nav-wrapper .adt-nav-text {
  background-color: var(--white);
  color: var(--neutral-2);
  font-weight: 600;
  position: relative;
  text-decoration: none;
  transition-duration: 0.2s, 0.2s;
  transition-property: color, background-color;
  transition-timing-function: ease, ease;
}
.f-navigation-primary.nav-wrapper .adt-nav-text:hover, .f-navigation-primary.nav-wrapper .adt-nav-text:focus {
  background-color: #ebf2ff;
  color: var(--action-default-hover);
}
.f-navigation-primary.nav-wrapper .nav-items-wrapper .adt-nav-item,
.f-navigation-primary.nav-wrapper .nav-items-wrapper .portlet {
  position: static;
}
.f-navigation-primary.nav-wrapper .search-wrapper.search-open {
  min-height: 80vh;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
  z-index: 2;
}
.f-navigation-primary.nav-wrapper .search-wrapper {
  align-items: center;
  background-color: var(--accent-10);
  border-radius: 0 0 8px 8px;
  bottom: auto;
  box-shadow: 0px 1.1px 4.05px -0.66px rgba(0, 0, 0, 0.14), 0px 3px 4.5px 0.3px rgba(0, 0, 0, 0.036), 0px 2.9px 6px 0.2px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  left: 0;
  opacity: 0;
  padding: 3.5rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0px, -800px, 0px);
  transform-style: preserve-3d;
  transition: transform 0.25s, opacity 0.25s;
  z-index: -2;
}
.f-navigation-primary.nav-wrapper .search-wrapper form.search {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 30rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper form.search input.search-input {
  background-color: transparent;
  border: 0 none #000;
  color: var(--white);
  font-size: 28px;
  font-weight: 600;
  height: 3.5rem;
  line-height: 32px;
  margin-bottom: 0;
  outline: none;
  padding: 0.5rem 0.75rem;
  width: 100%;
}
.f-navigation-primary.nav-wrapper .search-wrapper form.search input.search-input::placeholder {
  color: rgba(var(--white), 0.4);
  opacity: 1;
}
.f-navigation-primary.nav-wrapper .search-wrapper form.search input[type=search]::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.f-navigation-primary.nav-wrapper .search-wrapper form.search input[type=search]::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}
.f-navigation-primary.nav-wrapper .search-wrapper form.search input[type=search]::-webkit-search-decoration,
.f-navigation-primary.nav-wrapper .search-wrapper form.search input[type=search]::-webkit-search-cancel-button,
.f-navigation-primary.nav-wrapper .search-wrapper form.search input[type=search]::-webkit-search-results-button,
.f-navigation-primary.nav-wrapper .search-wrapper form.search input[type=search]::-webkit-search-results-decoration {
  display: none;
}
.f-navigation-primary.nav-wrapper .search-wrapper form.search .search-submit {
  -webkit-appearance: none;
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper form.search .search-submit svg {
  background-color: #142d5b;
  height: 3rem;
  stroke: var(--white);
  width: 3rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions {
  padding-top: 3.875rem;
  width: 30rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .st-nav-results-container .search-result em {
  font-style: normal;
  font-weight: 900;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .st-nav-results-container .st-loading-message {
  color: var(--white);
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .results-header {
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .results-header a:hover {
  color: var(--white);
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .results-header .popular,
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .results-header .suggested {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .results-header .all-results-link {
  color: var(--white);
  font-size: 1rem;
  line-height: 24px;
  opacity: 0.7;
  text-decoration: none;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .suggestion-links {
  -webkit-box-pack: justify;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 31rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .suggestion-links .utility-nav-link {
  -webkit-align-items: center;
  align-items: center;
  border-radius: 4px;
  -webkit-box-align: center;
  color: var(--accent-10);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  font-weight: 600;
  line-height: 20px;
  opacity: 0.7;
  padding: 0.625rem;
  text-decoration: none;
  -webkit-transition: background-color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease;
  transition: background-color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .suggestion-links .utility-nav-link.search-recommendation {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 1px;
  color: var(--white);
  margin-right: 1rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .suggestion-links .utility-nav-link.search-recommendation:hover {
  border: 1px solid var(--white);
  opacity: 1;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .search-results .search-result {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0);
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  color: var(--white);
  display: inline-block;
  margin-bottom: 1.5rem;
  padding: 1rem;
  text-decoration: none;
  -webkit-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
  width: 100%;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .search-results .search-result:hover {
  border-color: var(--white);
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .search-results .search-result .search-result-heading {
  color: var(--white);
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0;
  margin-top: 0;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .search-results .search-result .search-result-url {
  font-weight: 600;
  line-height: 20px;
  opacity: 0.8;
  padding-top: 0.75rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .search-results .st-no-results,
.f-navigation-primary.nav-wrapper .search-wrapper .suggestions .search-results .st-spelling-suggestion {
  color: var(--white);
}
.f-navigation-primary.nav-wrapper .search-wrapper .close-search {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  bottom: auto;
  display: flex;
  height: 2rem;
  left: auto;
  padding: 0.5rem;
  position: absolute;
  right: 3.5rem;
  top: 3.5rem;
  width: 2rem;
}
.f-navigation-primary.nav-wrapper .search-wrapper .close-search:hover {
  border: 1px solid var(--white);
  opacity: 1;
}
.f-navigation-primary.nav-wrapper .skip-to-footer-wrapper {
  background: var(--white);
  border-radius: 4px;
  box-shadow: 0px 1.1px 4.05px -0.66px rgba(0, 0, 0, 0.14), 0px 3px 4.5px 0.3px rgba(0, 0, 0, 0.036), 0px 2.9px 6px 0.2px rgba(0, 0, 0, 0.06);
  left: -50%;
  padding: 0.25rem 0.75rem;
  position: absolute;
  transform: translateX(-50%);
}
.f-navigation-primary.nav-wrapper .skip-to-footer-wrapper .skip-to-footer-text {
  color: var(--action-default-active);
  font-size: 19px;
  font-weight: 600;
  text-align: center;
}
.f-navigation-primary.nav-wrapper .skip-to-footer-wrapper:focus {
  left: 50%;
}
.f-navigation-primary .utility-nav {
  height: 3rem;
  margin: 0 auto;
  max-width: 1366px;
  position: relative;
  width: 100%;
  z-index: 2;
}
.f-navigation-primary .utility-nav .content-wrapper {
  height: 100%;
  width: 100%;
}
.f-navigation-primary .utility-nav .content-wrapper.utility {
  border-bottom: solid 1px #dadee3;
}
.f-navigation-primary .utility-nav .utility-nav-left .info-for {
  color: var(--accent-10);
  font-weight: 600;
  opacity: 0.6;
  padding-right: 0.5rem;
  text-align: center;
}
.f-navigation-primary .utility-nav .utility-nav-left .utility-nav-link {
  border-radius: 4px;
  color: var(--accent-10);
  display: inline-block;
  font-weight: 600;
  opacity: 0.7;
  padding: 0.625rem;
  text-decoration: none;
  transition-duration: 0.2s, 0.2s, 0.2s;
  transition-property: background-color, opacity, color;
  transition-timing-function: ease, ease, ease;
}
.f-navigation-primary .utility-nav .utility-nav-left .utility-nav-link:hover {
  background: #ebf2ff;
  color: var(--action-default-active);
  opacity: 1;
}
.f-navigation-primary .utility-nav .utility-nav-right {
  margin-right: -0.5rem;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown {
  margin-left: 1rem;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .language-dropdown-toggle {
  color: #222;
  cursor: pointer;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
  padding-right: 2.5rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .utility-nav-link.language-selector svg {
  fill: var(--accent-10);
  height: 1rem;
  margin-bottom: 0.0625rem;
  margin-right: 0.25rem;
  transition-duration: 0.2s;
  transition-property: fill;
  transition-timing-function: ease;
  width: 1rem;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .utility-nav-link.language-selector:hover {
  background-color: #ebf2ff;
  color: var(--action-default-active);
  opacity: 1;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .utility-nav-link.language-selector:hover svg {
  fill: var(--action-default-active);
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .utility-nav-link {
  align-items: center;
  border-radius: 4px;
  color: #000;
  display: flex;
  font-weight: 600;
  opacity: 0.7;
  padding: 0.625rem;
  transition-duration: 0.2s, 0.2s, 0.2s;
  transition-property: color, background-color, opacity;
  transition-timing-function: ease, ease, ease;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper {
  display: none;
  position: absolute;
  right: 0;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list {
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 2px 6px 0px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.25rem 0 0.25rem;
  width: 12.5rem;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list ul {
  padding-inline-start: 0;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item {
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-weight: 600;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.25rem 0.25rem 1.5rem;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item:active {
  background-color: #ebf2ff;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item:focus {
  background-color: #ebf2ff;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item:hover {
  background-color: #ebf2ff;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text {
  color: var(--neutral-2);
  width: 100%;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text:active {
  color: var(--neutral-2);
  text-decoration: none;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text:visited {
  color: var(--neutral-2);
  text-decoration: none;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text:focus {
  color: var(--neutral-2);
  text-decoration: none;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text:hover {
  color: var(--neutral-2);
  text-decoration: none;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item.selected {
  padding-left: 0.4375rem;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item.selected .language-entry-long-text {
  color: #004ad7;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .selected.osb-nav-item:before {
  content: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M9.62623%200.958909C10.0168%200.568385%2010.6499%200.568385%2011.0404%200.958909C11.431%201.34943%2011.431%201.9826%2011.0404%202.37312L5.04127%208.37229C5.04099%208.37257%205.04072%208.37285%205.04044%208.37312C4.64992%208.76365%204.01675%208.76365%203.62623%208.37312L0.292893%205.03979C-0.0976311%204.64927%20-0.0976311%204.0161%200.292893%203.62558C0.683417%203.23505%201.31658%203.23505%201.70711%203.62558L4.33333%206.2518L9.62623%200.958909Z%22%20fill%3D%22%23004AD7%22/%3E%0A%3C/svg%3E%0A");
  margin-right: 0.3125rem;
}
.f-navigation-primary .utility-nav .utility-nav-right .dropdown .dropdown-list-wrapper.list-open {
  display: block;
}
.f-navigation-primary .utility-nav .utility-nav-right .search-button {
  border-radius: 4px;
  display: inline-block;
  margin-left: 0.5rem;
  max-width: 100%;
  opacity: 0.7;
  position: relative;
  transition-duration: 0.2s, 0.2s, 0.2s;
  transition-property: opacity, background-color, stroke;
  transition-timing-function: ease, ease, ease;
}
.f-navigation-primary .utility-nav .utility-nav-right .search-button svg {
  height: 2.5rem;
  stroke: var(--accent-10);
  transition-duration: 0.2s;
  transition-property: stroke;
  transition-timing-function: ease;
  width: 2.5rem;
}
.f-navigation-primary .utility-nav .utility-nav-right .search-button:hover {
  background-color: #ebf2ff;
  opacity: 1;
}
.f-navigation-primary .utility-nav .utility-nav-right .search-button:hover svg {
  stroke: var(--action-default-active);
}
.f-navigation-primary .contact-sales,
.f-navigation-primary .contact-sales-container .w-button {
  background-color: var(--accent-6);
  border-radius: 4px;
  color: var(--white);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}
.f-navigation-primary .contact-sales:hover,
.f-navigation-primary .contact-sales-container .w-button:hover {
  background-color: var(--action-secondary-hover);
}
.f-navigation-primary .primary-nav {
  height: 4rem;
  margin: 0 auto;
  max-width: 1366px;
  padding: 0;
  width: 100%;
}
.f-navigation-primary .primary-nav .content-wrapper {
  height: 100%;
  justify-content: space-between;
  padding: 0 1.5rem;
  width: 100%;
}
.f-navigation-primary .primary-nav .content-wrapper .liferay-logo {
  border-radius: 4px;
  width: 9.5rem;
}
.f-navigation-primary .primary-nav .content-wrapper .liferay-logo svg {
  height: 3rem;
  width: 100%;
}
.f-navigation-primary .primary-nav .content-wrapper .liferay-logo svg:last-child {
  color: var(--black);
}
.f-navigation-primary .primary-nav .content-wrapper .liferay-logo:hover {
  background-color: #ebf2ff;
}
@media screen and (max-width: 991px) {
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    overflow: scroll;
    padding-bottom: 7.5rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    z-index: -1;
  }
}
.f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper {
  align-items: stretch;
  background-color: var(--white);
  padding-top: 5rem;
}
.f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .underline-container {
  display: none;
}
.f-navigation-primary .mobile-buttons {
  bottom: 0.75rem;
  padding: 0 1.5rem;
}
.f-navigation-primary .mobile-buttons .button-text-close {
  display: none;
}
.f-navigation-primary .mobile-buttons .mobile-menu.menu-open .button-text-close {
  display: block;
}
.f-navigation-primary .mobile-buttons .mobile-menu.menu-open .button-text-menu {
  display: none;
}
@media (max-width: 599px) {
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu {
    box-shadow: none;
    grid-row-gap: 0;
    height: auto;
    max-height: 0;
    opacity: 1;
    overflow: hidden;
    padding: 0;
    position: static;
    transform: none;
    transition: max-height 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    visibility: visible;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu .adt-submenu-outer-wrapper .adt-submenu-inner-wrapper {
    display: flex;
    flex-direction: column;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu .adt-submenu-section {
    display: flex;
    flex-direction: column;
    grid-template-rows: none;
    padding-left: 1.5rem;
    padding-right: 2rem;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-header {
    border-width: 0;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content {
    padding-bottom: 2.5rem;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-item-content:not(.image-type) {
    border-left: solid 2px var(--neutral-7);
    max-width: none;
    padding-left: 1rem;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section {
    display: none;
    grid-row-gap: 0;
  }
  .f-navigation-primary .nav .search-wrapper.search-open {
    height: 100vh;
    overflow: auto;
    padding: 5rem 1.5rem 1.5rem;
    z-index: 4;
  }
  .f-navigation-primary .nav .search-wrapper.search-open .close-search {
    right: 1.5rem;
    top: 1rem;
  }
  .f-navigation-primary .nav .search-wrapper.search-open .search {
    width: 100%;
  }
  .f-navigation-primary .nav .search-wrapper.search-open .search .search-input > .search-submit {
    background-color: var(--accent-10);
  }
  .f-navigation-primary .nav .search-wrapper.search-open .suggestions {
    width: 100%;
  }
  .f-navigation-primary .nav .search-wrapper.search-open .suggestions .suggestion-links {
    justify-content: center;
    width: 100%;
  }
}
@media (max-width: 599px) and (max-width: 400px) {
  .f-navigation-primary .nav .search-wrapper.search-open .suggestions .suggestion-links {
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
  }
  .f-navigation-primary .nav .search-wrapper.search-open .suggestions .suggestion-links a:last-child {
    margin-top: 1rem;
  }
  .f-navigation-primary .nav .search-wrapper.search-open .suggestions .suggestion-links a.utility-nav-link.search-recommendation {
    justify-content: center;
    margin-right: 0;
    width: 30%;
  }
}
@media (max-width: 599px) {
  .f-navigation-primary .utility-nav {
    background-color: var(--white);
    border-bottom: 1px solid var(--neutral-8);
    display: block;
    height: 4rem;
    padding: 0.75rem 1.5rem;
    position: relative;
    z-index: 3;
  }
  .f-navigation-primary .utility-nav .content-wrapper {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;
    width: 100%;
  }
  .f-navigation-primary .utility-nav .content-wrapper.utility {
    border-width: 0;
  }
  .f-navigation-primary .utility-nav .content-wrapper .liferay-logo {
    display: block;
    width: 7.5rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .liferay-logo svg {
    height: 3rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .liferay-logo svg:last-child {
    color: var(--black);
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-left {
    display: none;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right {
    margin-right: -0.5rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown {
    margin-left: 1rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .language-dropdown-toggle {
    color: #222;
    cursor: pointer;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem;
    padding-right: 2.5rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: top;
    white-space: nowrap;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .utility-nav-link.language-selector svg {
    fill: var(--accent-10);
    height: 1rem;
    margin-bottom: 0.0625rem;
    margin-right: 0.25rem;
    transition-duration: 200;
    transition-property: fill;
    transition-timing-function: ease;
    width: 1rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .utility-nav-link.language-selector:hover {
    background-color: #ebf2ff;
    color: var(--action-default-active);
    opacity: 1;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .utility-nav-link.language-selector:hover svg {
    fill: var(--action-default-active);
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .utility-nav-link {
    align-items: center;
    border-radius: 4px;
    color: #000;
    display: flex;
    font-weight: 600;
    opacity: 0.7;
    padding: 0.625rem;
    transition-duration: 0.2s, 0.2s, 0.2s;
    transition-property: color, background-color, opacity;
    transition-timing-function: ease, ease, ease;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper {
    display: none;
    position: absolute;
    right: 0;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list {
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 2px 6px 0px;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.25rem 0 0.25rem;
    width: 12.5rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list ul {
    padding-inline-start: 0;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item {
    align-items: center;
    border-radius: 4px;
    display: flex;
    font-weight: 600;
    margin-bottom: 0.5rem;
    padding: 0.25rem 0.25rem 0.25rem 1.5rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item:active {
    background-color: #ebf2ff;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item:focus {
    background-color: #ebf2ff;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item:hover {
    background-color: #ebf2ff;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text {
    color: var(--neutral-2);
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text:active {
    color: var(--neutral-2);
    text-decoration: none;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text:visited {
    color: var(--neutral-2);
    text-decoration: none;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text:focus {
    color: var(--neutral-2);
    text-decoration: none;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item .language-entry-long-text:hover {
    color: var(--neutral-2);
    text-decoration: none;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item.selected {
    padding-left: 0.4375rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .osb-nav-item.selected .language-entry-long-text {
    color: #004ad7;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper .dropdown-list .selected.osb-nav-item:before {
    content: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M9.62623%200.958909C10.0168%200.568385%2010.6499%200.568385%2011.0404%200.958909C11.431%201.34943%2011.431%201.9826%2011.0404%202.37312L5.04127%208.37229C5.04099%208.37257%205.04072%208.37285%205.04044%208.37312C4.64992%208.76365%204.01675%208.76365%203.62623%208.37312L0.292893%205.03979C-0.0976311%204.64927%20-0.0976311%204.0161%200.292893%203.62558C0.683417%203.23505%201.31658%203.23505%201.70711%203.62558L4.33333%206.2518L9.62623%200.958909Z%22%20fill%3D%22%23004AD7%22/%3E%0A%3C/svg%3E%0A");
    margin-right: 0.3125rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .dropdown .dropdown-list-wrapper.list-open {
    display: block;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .search-button {
    border-radius: 4px;
    display: inline-block;
    margin-left: 0.5rem;
    opacity: 0.7;
    position: relative;
    transition-duration: 0.2s, 0.2s, 0.2s;
    transition-property: opacity, background-color, stroke;
    transition-timing-function: ease, ease, ease;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .search-button svg {
    height: 2.5rem;
    stroke: var(--accent-10);
    transition-duration: 0.2s;
    transition-property: stroke;
    transition-timing-function: ease;
    width: 2.5rem;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .search-button:hover {
    background-color: #ebf2ff;
    opacity: 1;
  }
  .f-navigation-primary .utility-nav .content-wrapper .utility-nav-right .search-button:hover svg {
    stroke: var(--action-default-active);
  }
}
@media (max-width: 599px) {
  .f-navigation-primary .primary-nav {
    border: none;
    height: 0;
  }
  .f-navigation-primary .primary-nav .content-wrapper {
    padding: 0 1.5rem;
    position: relative;
  }
  .f-navigation-primary .primary-nav .content-wrapper .liferay-logo {
    display: none;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper {
    align-items: center;
    background-color: transparent;
    height: 0;
    left: 0;
    opacity: 0;
    padding-bottom: 8.5rem;
    padding-top: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    visibility: hidden;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper.menu-open {
    background-color: var(--white);
    height: 100vh;
    opacity: 1;
    overflow-x: hidden;
    top: 0;
    transform: translateY(0);
    visibility: visible;
    z-index: 2;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper.menu-open::after {
    background-color: var(--white);
    content: "";
    height: 100vh;
    width: 100vw;
    z-index: -3;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-navigation {
    background: var(--white);
    display: flex;
    flex-direction: column;
    overflow: visible;
    width: 100vw;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-navigation .adt-nav-text {
    padding-bottom: 0.875rem;
    padding-top: 0.875rem;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;
    z-index: 3;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-navigation .adt-nav-text .adt-nav-title {
    padding-left: 1.5rem;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-navigation .adt-angle-down-svg {
    position: absolute;
    right: 1.5rem;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .underline-container {
    display: block;
    padding: 0.375rem 1.5rem 0 1.5rem;
    width: 100%;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .underline-container .underline {
    border: 1px solid var(--neutral-7);
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .contact-sales-container {
    display: flex;
    padding: 0 1.5rem 10rem;
    width: 100%;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .contact-sales-container .contact-sales {
    box-shadow: 0px 1.1px 4.05px -0.66px rgba(0, 0, 0, 0.14), 0px 3px 4.5px 0.3px rgba(0, 0, 0, 0.036), 0px 2.9px 6px 0.2px rgba(0, 0, 0, 0.06);
    margin: 0;
    text-align: center;
    width: 100%;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .utility-nav-left {
    background-color: var(--white);
    display: block;
    padding: 1.5rem 1.5rem 2rem;
    width: 100%;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .utility-nav-left .info-for {
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    width: 100%;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .utility-nav-left .info-for > a {
    color: var(--neutral-3);
    font-size: 1rem;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .navbar-nav {
    background: var(--white);
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .navbar-nav .nav-link {
    padding-bottom: 0.875rem;
    padding-top: 0.875rem;
    width: 100%;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .navbar-nav .nav-link > span {
    padding-left: 1rem;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .navbar-nav .lfr-nav-child-toggle {
    position: absolute;
    right: 1.5rem;
  }
}
@media (max-width: 599px) {
  .f-navigation-primary .mobile-buttons {
    bottom: 0.75rem;
    position: fixed;
    right: 50%;
    transform: translate(50%, 0);
    z-index: 3;
  }
  .f-navigation-primary .mobile-buttons .contact-sales {
    display: none;
  }
  .f-navigation-primary .mobile-buttons .mobile-menu {
    background-color: var(--white);
    border: 1px solid var(--action-default);
    border-radius: 1.5rem;
    box-shadow: 0px 1.1px 4.05px -0.66px rgba(0, 0, 0, 0.14), 0px 3px 4.5px 0.3px rgba(0, 0, 0, 0.036), 0px 2.9px 6px 0.2px rgba(0, 0, 0, 0.06);
    color: var(--action-default);
    display: block;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    width: 7.5rem;
  }
}
@media (min-width: 600px) and (max-width: 1199px) {
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu {
    box-shadow: none;
    grid-row-gap: 0;
    height: auto;
    margin: 0;
    max-height: 0;
    opacity: 1;
    padding: 0;
    position: static;
    transform: none;
    transition: max-height 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    visibility: visible;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu .adt-submenu-outer-wrapper .adt-submenu-inner-wrapper {
    display: flex;
    flex-direction: column;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section {
    grid-template-rows: none;
    padding: 2rem;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-1, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-2, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-3, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-4, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-5, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-6, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-7, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-8, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-9, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-10, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-11, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section .adt-submenu-item-content.grid-column-span-12 {
    grid-column-start: span 1;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._3-section-span, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._4-section-span, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._5-section-span, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._6-section-span, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._7-section-span, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._8-section-span, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._9-section-span, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._10-section-span, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._11-section-span, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._12-section-span {
    -ms-grid-column-span: 2;
    grid-column-start: span 2;
  }
  .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._3-section-span .adt-submenu-header, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._4-section-span .adt-submenu-header, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._5-section-span .adt-submenu-header, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._6-section-span .adt-submenu-header, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._7-section-span .adt-submenu-header, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._8-section-span .adt-submenu-header, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._9-section-span .adt-submenu-header, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._10-section-span .adt-submenu-header, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._11-section-span .adt-submenu-header, .f-navigation-primary.nav-wrapper .adt-nav-item .adt-submenu-section._12-section-span .adt-submenu-header {
    -ms-grid-column-span: 2;
    grid-column-start: span 2;
  }
  .f-navigation-primary .primary-nav {
    padding: 0;
  }
  .f-navigation-primary .primary-nav .content-wrapper {
    padding-left: 1.5rem;
    padding-right: 2rem;
    position: relative;
  }
  .f-navigation-primary .primary-nav .content-wrapper .liferay-logo {
    padding-left: 0.5rem;
  }
  .f-navigation-primary .primary-nav .content-wrapper .liferay-logo svg {
    height: 3rem;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper {
    align-items: center;
    background-color: transparent;
    height: 0;
    left: 0;
    opacity: 0;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    visibility: hidden;
    z-index: -1;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper.menu-open {
    background-color: var(--white);
    height: 100vh;
    opacity: 1;
    overflow: auto;
    top: 4rem;
    transform: translateY(0);
    visibility: visible;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .contact-sales-container,
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .utility-nav-left {
    display: none;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-navigation {
    background: var(--white);
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px - 4rem);
    overflow: auto;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-navigation .adt-nav-text {
    padding: 0.875rem 1rem;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;
    z-index: 3;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-navigation .adt-nav-text .adt-nav-title {
    padding-left: 1rem;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-navigation .adt-angle-down-svg {
    position: absolute;
    right: 1.5rem;
  }
  .f-navigation-primary .mobile-buttons {
    display: flex;
    padding-right: 1.5rem;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .f-navigation-primary .mobile-buttons .tablet {
    display: inline-block;
    margin-left: 0;
    margin-right: 1rem;
    top: auto;
  }
  .f-navigation-primary .mobile-buttons .mobile-menu {
    border: 1px solid var(--action-default);
    border-radius: 4px;
    color: var(--action-default);
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
    z-index: 1;
  }
  .f-navigation-primary .mobile-buttons .mobile-menu .button-text-close {
    padding: 0.5rem 1rem;
  }
  .f-navigation-primary .mobile-buttons .mobile-menu .button-text-menu {
    padding: 0.5rem 1rem;
  }
  .f-navigation-primary .utility-nav {
    padding: 0 2rem;
  }
}
@media (min-width: 1200px) {
  .f-navigation-primary .nav.show-border {
    border-bottom: 1px solid var(--neutral-8);
  }
  .f-navigation-primary .nav .utility-nav .content-wrapper {
    margin: 0 4rem;
  }
  .f-navigation-primary .primary-nav {
    border-bottom: 1px solid transparent;
  }
  .f-navigation-primary .primary-nav .content-wrapper {
    margin: 0 4rem;
    padding: 0;
  }
  .f-navigation-primary .primary-nav .content-wrapper .liferay-logo svg {
    height: 3.37rem;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper {
    align-items: center;
    display: flex;
    padding: 0;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-nav-item.dropdown-open .adt-nav-text::after {
    left: 0;
    width: 100%;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-nav-item .adt-nav-text {
    border-radius: 4px;
    padding: 0;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-nav-item .adt-nav-text .adt-nav-title {
    cursor: pointer;
    font-size: 1.125rem;
    padding: 0.875rem 1.125rem 0.688rem;
    position: relative;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-nav-item .adt-nav-text .adt-nav-title .adt-angle-down-svg {
    display: none;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-nav-item .adt-nav-text::after {
    background: #004ad7;
    bottom: -5px;
    content: "";
    height: 3px;
    left: 50%;
    position: absolute;
    transition: left ease-in 0.3s, width ease-in 0.3s;
    width: 0%;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .adt-nav-item .adt-submenu {
    border-radius: 0 0 0.5rem 0.5rem;
    box-shadow: 0px 7.4px 11.4px -7.59px rgba(0, 0, 0, 0.14), 0px 24px 36px 3.45px rgba(0, 0, 0, 0.078), 0px 9.2px 48px 8.6px rgba(0, 0, 0, 0.06);
    padding-bottom: 1rem;
  }
  .f-navigation-primary .primary-nav .content-wrapper .nav-items-wrapper .utility-nav-left {
    display: none;
  }
  .f-navigation-primary .mobile-buttons {
    display: none;
  }
}

#footer.f-navigation-footer {
  background-color: var(--accent-10);
  color: var(--white);
  padding: 1em;
}
@media screen and (min-width: 767px) {
  #footer.f-navigation-footer {
    padding: 0 3em;
  }
}
#footer.f-navigation-footer .max-nav {
  margin: 0 auto;
  max-width: 1240px;
  padding: 3em 0 !important;
}
#footer.f-navigation-footer ul {
  margin: 0;
  padding: 0;
}
#footer.f-navigation-footer li {
  line-height: 20px;
  list-style: none;
  margin: 0;
}
#footer.f-navigation-footer h4 {
  color: var(--white);
  line-height: 1.5em;
  margin: 0;
  padding: 1.5em 0;
}
#footer.f-navigation-footer a {
  display: block;
}
#footer.f-navigation-footer a:hover {
  text-decoration: none;
}
#footer.f-navigation-footer .footer-content {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 1em 0;
}
@media screen and (min-width: 767px) {
  #footer.f-navigation-footer .footer-content {
    flex-direction: row;
  }
}
#footer.f-navigation-footer .footer-content .footer-navigation {
  -webkit-box-flex: 5;
  flex: 5;
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding-bottom: 1.5em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (min-width: 767px) {
  #footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper {
    margin-left: -2em;
  }
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper .languages .selected {
  display: none;
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper.class-toggle-active .dropdown-content {
  height: auto;
  opacity: 1;
  overflow: visible;
  padding: 0.5em;
  visibility: visible;
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper.class-toggle-active .dropdown-content a {
  color: var(--black);
  padding: 0.375em 0.75em;
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper .dropdown-content {
  background: var(--white);
  border-radius: 0.25em;
  -webkit-box-shadow: 0px 1.1px 4.05px -0.66px rgba(0, 0, 0, 0.14), 0px 3px 4.5px 0.3px rgba(0, 0, 0, 0.036), 0px 2.9px 6px 0.2px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1.1px 4.05px -0.66px rgba(0, 0, 0, 0.14), 0px 3px 4.5px 0.3px rgba(0, 0, 0, 0.036), 0px 2.9px 6px 0.2px rgba(0, 0, 0, 0.06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 0;
  line-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transition: opacity 0.25s cubic-bezier(0.77, 0, 0.175, 1), visibility 0.25s cubic-bezier(0.77, 0, 0.175, 1);
  transition: opacity 0.25s cubic-bezier(0.77, 0, 0.175, 1), visibility 0.25s cubic-bezier(0.77, 0, 0.175, 1);
  visibility: hidden;
  white-space: nowrap;
  z-index: 1000;
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper .dropdown-content::after {
  background: var(--white);
  border-radius: 1.25em 0 0;
  bottom: -7px;
  content: "";
  height: 14px;
  left: 10%;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: left 0.25s cubic-bezier(0.77, 0, 0.175, 1);
  transition: left 0.25s cubic-bezier(0.77, 0, 0.175, 1);
  width: 14px;
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper .dropdown-content.top {
  bottom: calc(100% + 14px);
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper .dropdown-content.right {
  left: 0;
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper .language-selector {
  align-items: center;
  -webkit-box-align: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
}
#footer.f-navigation-footer .footer-content .footer-navigation .language-wrapper .language-selector .current-language {
  font-size: 1.15em;
  font-weight: 600;
  padding-left: 0.5em;
}
#footer.f-navigation-footer .footer-content .footer-navigation .navigation {
  flex-wrap: wrap;
}
#footer.f-navigation-footer .footer-content .footer-navigation .navigation a {
  color: var(--white);
  font-weight: 600;
  padding-bottom: 1em;
}
#footer.f-navigation-footer .footer-content .footer-navigation .navigation a:hover {
  color: var(--neutral-5);
}
#footer.f-navigation-footer .footer-content .footer-navigation .navigation div {
  box-sizing: border-box;
  padding-right: 0;
  width: 100%;
}
@media screen and (min-width: 767px) {
  #footer.f-navigation-footer .footer-content .footer-navigation .navigation div {
    width: initial;
  }
}
@media (min-width: 900px) {
  #footer.f-navigation-footer .footer-content .footer-navigation .navigation div {
    padding-right: 4em;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  #footer.f-navigation-footer .footer-content .footer-navigation .navigation div {
    padding-right: 3em;
  }
}
#footer.f-navigation-footer .footer-content .footer-navigation .navigation div .nav-item-header {
  font-size: 0.833125em;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 0;
  text-transform: uppercase;
}
#footer.f-navigation-footer .footer-content .footer-liferay-connect {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-top: 4em;
}
@media screen and (min-width: 767px) {
  #footer.f-navigation-footer .footer-content .footer-liferay-connect {
    padding-top: 0;
  }
}
#footer.f-navigation-footer .footer-content .footer-liferay-connect .social-nav {
  margin-left: -1em;
  margin-right: -1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
@media screen and (min-width: 767px) {
  #footer.f-navigation-footer .footer-content .footer-liferay-connect .social-nav {
    margin-left: 0;
  }
}
#footer.f-navigation-footer .footer-content .footer-liferay-connect .social-nav a {
  color: var(--white);
  height: 3.25em;
}
#footer.f-navigation-footer .footer-content .footer-liferay-connect .social-nav svg {
  display: inline-block;
  fill: var(--white);
  height: 1.25em;
  margin: 0.5em;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  width: 1.25em;
}
#footer.f-navigation-footer .footer-content .footer-liferay-connect .contact-info {
  color: var(--white);
  text-align: left;
}
@media screen and (min-width: 767px) {
  #footer.f-navigation-footer .footer-content .footer-liferay-connect .contact-info {
    text-align: right;
  }
}
#footer.f-navigation-footer .footer-content .footer-liferay-connect .contact-info a {
  color: var(--white);
  cursor: pointer;
  line-height: 1.5em;
  margin: 0;
  padding: 1.5em 0;
}
#footer.f-navigation-footer .footer-content .footer-liferay-connect .contact-info p {
  font-size: 1.125em;
}
#footer.f-navigation-footer .fine-print {
  background-color: var(--accent-10);
  box-sizing: border-box;
  color: var(--neutral-7);
  display: flex;
  flex-wrap: wrap;
  padding: 2em 0 5em;
  text-align: left;
}
#footer.f-navigation-footer .fine-print a {
  color: var(--neutral-7);
  font-size: 0.9em;
  width: 100%;
}
@media screen and (min-width: 767px) {
  #footer.f-navigation-footer .fine-print a {
    width: initial;
  }
}
#footer.f-navigation-footer .fine-print a:hover {
  color: var(--black);
}
#footer.f-navigation-footer .fine-print p {
  font-size: 0.9em;
  width: 100%;
}
@media screen and (min-width: 767px) {
  #footer.f-navigation-footer .fine-print p {
    width: initial;
  }
}

html:not(#__):not(#___) body .page-editor__layout-viewport--size-tablet,
html:not(#__):not(#___) body .page-editor__layout-viewport--size-landscapeMobile,
html:not(#__):not(#___) body .page-editor__layout-viewport--size-portraitMobile {
  height: 100vh;
}